.m-4 {
  margin: 1.5em !important;
}
.ml-4 {
  margin-left: 1.5em !important;
}
.mr-4 {
  margin-right: 1.5em !important;
}
.mb-4 {
  margin-bottom: 1.5em !important;
}
.mt-4 {
  margin-top: 1.5em !important;
}
.mx-4 {
  margin-left: 1.5em !important;
  margin-right: 1.5em !important;
}
.my-4 {
  margin-top: 1.5em !important;
  margin-bottom: 1.5em !important;
}
.p-4 {
  padding: 1.5em !important;
}
.pl-4 {
  padding-left: 1.5em !important;
}
.pr-4 {
  padding-right: 1.5em !important;
}
.pb-4 {
  padding-bottom: 1.5em !important;
}
.pt-4 {
  padding-top: 1.5em !important;
}
.p-x-4 {
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
}
.p-y-4 {
    padding-top: 1.5em !important;
    padding-bottom: 1.5em !important;
}