body {
    margin: 0;
    padding: 0;
    background-color: #f0f2f5;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
